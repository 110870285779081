.App {
    text-align: center;
    font-family: 'Sofia Pro', Arial, Helvetica, sans-serif;
    font-weight: 400;
    color: white;
    min-height: 100vh;
    padding: 0 20px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 2;
    background-color: #2b44ff;

    @media screen and (max-width: 900px) {
        flex-direction: column;
    }

    .person {
        margin-right: 40px;
        @media screen and (max-width: 900px) {
            display: block;
            margin: 0;
            margin-top: 40px;
        }
        @media screen and (max-width: 600px) {
            margin-top: 40px;
        }

    }

    .content {
        font-size: 1.5rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        font-weight: 400;
        color: white;
        text-align: left;
        padding-bottom: 20px;
        @media screen and (max-width: 900px) {
            text-align: center;
            align-items: center;
        }
        @media screen and (max-width: 500px) {
            font-size: 1.3rem;
            margin-top: 30px;
        }
        a {
            text-decoration: underline;
            font-weight: 600;
            color: white;
        }
        h1 {
            font-weight: 600;
            margin: 0;
            margin-bottom: 3px;
            color: white;
            font-size: 4rem;
            @media screen and (max-width: 500px) {
                font-size: 3rem;
            }
        }
    }

}