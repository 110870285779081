@font-face {
  font-family: 'Sofia Pro';
  font-weight: 400;
  src: url("/static/media/Sofia Pro Light.89ed709e.otf");
}

@font-face {
  font-family: 'Sofia Pro';
  font-weight: 200;
  src: url("/static/media/Sofia Pro UltraLight.3367823c.otf");
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.App {
  text-align: center;
  font-family: 'Sofia Pro', Arial, Helvetica, sans-serif;
  font-weight: 400;
  color: white;
  min-height: 100vh;
  padding: 0 20px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 2;
  background-color: #2b44ff; }
  @media screen and (max-width: 900px) {
    .App {
      flex-direction: column; } }
  .App .person {
    margin-right: 40px; }
    @media screen and (max-width: 900px) {
      .App .person {
        display: block;
        margin: 0;
        margin-top: 40px; } }
    @media screen and (max-width: 600px) {
      .App .person {
        margin-top: 40px; } }
  .App .content {
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    font-weight: 400;
    color: white;
    text-align: left;
    padding-bottom: 20px; }
    @media screen and (max-width: 900px) {
      .App .content {
        text-align: center;
        align-items: center; } }
    @media screen and (max-width: 500px) {
      .App .content {
        font-size: 1.3rem;
        margin-top: 30px; } }
    .App .content a {
      text-decoration: underline;
      font-weight: 600;
      color: white; }
    .App .content h1 {
      font-weight: 600;
      margin: 0;
      margin-bottom: 3px;
      color: white;
      font-size: 4rem; }
      @media screen and (max-width: 500px) {
        .App .content h1 {
          font-size: 3rem; } }

